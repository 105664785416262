import React from "react";
import { useTranslation } from "react-i18next";
import { styled } from "goober";
import { mobile, mobileMedium } from "clutch/src/Style/style.mjs";

import SearchBar from "@/search/SearchBar.jsx";
import useMediaQuery from "@/util/use-media-query.mjs";

const SearchBarWrapper = styled("div", React.forwardRef)`
  position: relative;

  width: 100%;
  max-width: var(--sp-container);
  height: var(--sp-16);
  margin: 0 auto;
  margin-top: var(--header-padding);
  padding: var(--sp-3) var(--sp-4);

  pointer-events: none;

  label {
    width: 100%;
    margin: 0 auto;

    ${mobile} {
      max-width: unset;
      margin: 0 auto;
    }
  }
`;

export const SearchContainer = () => {
  const isMobile = useMediaQuery(mobile);
  const isMobileMedium = useMediaQuery(mobileMedium);

  const { t } = useTranslation();

  return (
    <SearchBarWrapper>
      <SearchBar
        id="home-search-input"
        placeholder={
          !isMobileMedium
            ? !isMobile
              ? t(
                  "home:search.placeholder.long",
                  "Search profiles, champions, agents, legends, and more!",
                )
              : t(
                  "home:search.placeholder.short",
                  "Search profiles, champions, agents...",
                )
            : undefined
        }
      />
    </SearchBarWrapper>
  );
};
