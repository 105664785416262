import React, { useCallback } from "react";

import { ChampionStatWrapper } from "@/feature-reskin-champion-gg/ChampionStat.style.jsx";
import type { ChampStat } from "@/feature-reskin-champion-gg/types.js";
import { ROLE_SYMBOL_TO_STR } from "@/game-lol/constants/constants.mjs";
import { getWinRateColor } from "@/game-lol/utils/colors.mjs";
import getRoleIcon from "@/game-lol/utils/get-role-icon.mjs";
import { classNames } from "@/util/class-names.mjs";
import { formatToPercent } from "@/util/i18n-helper.mjs";

export const ChampionStat = ({ champion }: { champion: ChampStat }) => {
  const RoleIcons = useCallback(
    () =>
      champion.roles
        ?.sort((a, b) => {
          if (a === champion.role) return -1;
          if (b === champion.role) return 1;
          return 0;
        })
        .map((role) => {
          const Role = getRoleIcon(role);
          return (
            <a
              href={`/champion/${champion.key}/${ROLE_SYMBOL_TO_STR[role].internal}`}
              {...classNames("role", role === champion.role ? "primary" : "")}
              key={role.toString()}
            >
              <Role />
            </a>
          );
        }),
    [champion.key, champion.role, champion.roles],
  );

  return (
    <ChampionStatWrapper>
      <a
        href={`/champion/${champion.key}/${
          ROLE_SYMBOL_TO_STR[champion.role]?.internal
        }`}
        className="avatar"
      >
        <img src={champion.avatarUrl} alt={champion.name} />
      </a>
      <div className="info">
        <span className="name">{champion.name || "..."}</span>
        <span className="roles">
          <RoleIcons />
        </span>
        <span
          className="pick-rate"
          style={{
            color: champion?.winPercent
              ? getWinRateColor(champion.winPercent * 100)
              : "var(--shade3)",
          }}
        >
          {champion.winPercent
            ? formatToPercent(undefined, champion.winPercent, {
                min: 1,
                max: 1,
              })
            : "--%"}
        </span>
      </div>
    </ChampionStatWrapper>
  );
};
