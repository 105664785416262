import { styled } from "goober";

export const ChampionListTableWrapper = styled("div")`
  background-color: var(--shade6);
  border-radius: var(--sp-1_5);
  padding: var(--sp-4) var(--sp-5);
  flex: 1;
  max-width: 360px;

  .link {
    display: flex;
    color: var(--shade0);
    gap: var(--sp-2);
    align-items: center;
  }

  section {
    display: flex;
    flex-direction: column;
    gap: var(--sp-2);
  }

  .headers {
    display: flex;
    gap: var(--sp-2);
    margin: var(--sp-4) 0 var(--sp-2);

    .header {
      flex: 1;
      font-size: var(--sp-3);
      padding-left: var(--sp-2);
    }
  }

  .row {
    display: grid;
    grid-template-columns: var(--sp-12_5) 1fr 1fr;
    gap: var(--sp-2);
    height: var(--sp-12_5);

    .cell {
      flex: 1;
      display: flex;
      gap: var(--sp-2);
      align-items: center;
      justify-content: center;
      font-size: var(--sp-3_5);

      .avatar {
        border-radius: 50%;
        overflow: hidden;
        background-color: var(--shade8);
        transform: scale(1);
        transition: all 0.1s ease;
      }

      .percent {
        transition: color 0.1s ease;
        min-width: var(--sp-12_5);
      }

      svg {
        height: var(--sp-6);
        width: var(--sp-6);
      }

      img {
        height: var(--sp-8);
        width: var(--sp-8);
      }

      &:hover {
        .avatar,
        svg {
          transform: scale(1.1);
        }
      }
    }

    &:hover {
      filter: brightness(1.1);
    }

    &:nth-child(2n + 1) {
      background-color: var(--shade7);
    }
  }

  @media (max-width: 600px) {
    max-width: 100%;
    width: 100%;
  }
`;

export const ChampionListContainer = styled("div")`
  min-height: 360px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: var(--sp-3);

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`;
