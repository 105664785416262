import React from "react";
import { t } from "i18next";

import { ChampionListTableWrapper } from "@/feature-reskin-champion-gg/ChampionTable.style.jsx";
import type {
  ChampionTableRowType,
  ChampStatsByRole,
} from "@/feature-reskin-champion-gg/types.js";
import { ROLE_SYMBOL_TO_STR } from "@/game-lol/constants/constants.mjs";
import { getWinRateColor } from "@/game-lol/utils/colors.mjs";
import getRoleIcon from "@/game-lol/utils/get-role-icon.mjs";
import ChevronRight from "@/inline-assets/chevron-right.svg";
import { classNames } from "@/util/class-names.mjs";
import { formatToPercent } from "@/util/i18n-helper.mjs";

const ChampionTableRow = ({
  roleKey,
  left,
  right,
  dataKey,
}: ChampionTableRowType) => {
  const RoleIcon = getRoleIcon(roleKey);
  return (
    <div className="row">
      <a
        href={`/statistics/overview?role=${ROLE_SYMBOL_TO_STR[roleKey]?.key}`}
        className="cell"
      >
        <RoleIcon />
      </a>
      <a
        className="cell"
        href={`/champion/${left.key}/${ROLE_SYMBOL_TO_STR[roleKey]?.internal}`}
      >
        <span {...classNames("avatar", !left.avatarUrl ? "loading" : "")}>
          <img
            src={left?.avatarUrl}
            onLoad={(el) => (el.currentTarget.style.opacity = "1")}
            alt={left.name}
          />
        </span>
        {dataKey === "winRate" ? (
          <span
            className="percent"
            style={{
              color: left?.winPercent
                ? getWinRateColor(left.winPercent * 100)
                : "var(--shade3)",
            }}
          >
            {left?.winPercent &&
              formatToPercent(undefined, left?.winPercent, { min: 1, max: 1 })}
          </span>
        ) : (
          <span className="percent">{left?.name}</span>
        )}
      </a>
      <a
        className="cell"
        href={`/champion/${right.key}/${ROLE_SYMBOL_TO_STR[roleKey]?.internal}`}
      >
        <span {...classNames("avatar", !right.avatarUrl ? "loading" : "")}>
          <img
            src={right?.avatarUrl}
            onLoad={(el) => (el.currentTarget.style.opacity = "1")}
            alt={right.name}
          />
        </span>
        {dataKey === "winRate" ? (
          <span
            className="percent"
            style={{
              color: right?.winPercent
                ? getWinRateColor(right.winPercent * 100)
                : "var(--shade3)",
            }}
          >
            {right?.winPercent &&
              formatToPercent(undefined, right?.winPercent, { min: 1, max: 1 })}
          </span>
        ) : (
          <span className="percent">{right?.name}</span>
        )}
      </a>
    </div>
  );
};

type ChampionTableTypes = {
  title: Translation;
  url: string;
  dataKey: "winRate" | "tier";
  data: ChampStatsByRole;
};

export const ChampionTable = ({
  title,
  url,
  dataKey,
  data,
}: ChampionTableTypes) => {
  return (
    <ChampionListTableWrapper>
      <a className="link" href={url}>
        {t(...title)}
        <ChevronRight />
      </a>
      <section>
        <div className="headers">
          <div className="header">
            {t("championgg:home.table.win-rate.role", "Role")}
          </div>
          <div className="header">
            {t("championgg:home.table.win-rate.highest", "Highest")}
          </div>
          <div className="header">
            {t("championgg:home.table.win-rate.lowest", "Lowest")}
          </div>
        </div>
        <div className="rows">
          {Object.getOwnPropertySymbols(data).map((roleKey) => {
            const role = roleKey.toString();
            const { best, worst } = data[roleKey][dataKey];
            return (
              <ChampionTableRow
                key={role}
                dataKey={dataKey}
                roleKey={roleKey}
                left={best}
                right={worst}
              />
            );
          })}
        </div>
      </section>
    </ChampionListTableWrapper>
  );
};
