import { useMemo } from "react";

import { readState } from "@/__main__/app-state.mjs";
import type {
  ChampStat,
  ChampStatsByRole,
} from "@/feature-reskin-champion-gg/types.js";
import { ROLE_SYMBOLS } from "@/game-lol/constants/constants.mjs";
import Static from "@/game-lol/utils/static.mjs";
import {
  getDefaultedFiltersForChampions,
  getSearchParamsForChampions,
  getStaticData,
} from "@/game-lol/utils/util.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const roleSymbols = [
  ROLE_SYMBOLS.top,
  ROLE_SYMBOLS.jungle,
  ROLE_SYMBOLS.mid,
  ROLE_SYMBOLS.adc,
  ROLE_SYMBOLS.support,
];

function getChampionFilters(searchParams, opts = { isSynergies: false }) {
  const { isSynergies } = opts;
  const filters = getDefaultedFiltersForChampions(searchParams);
  const urlParams = getSearchParamsForChampions(isSynergies, filters);
  const hash = urlParams.toString();
  return { filters, hash };
}

export function useChampionRankings() {
  const stats = useSnapshot(readState);
  const champions = getStaticData("champions") ?? [];
  const staticData = stats.lol.staticData;
  const { hash } = getChampionFilters(new URLSearchParams());
  const championStats = stats.lol.championStats[hash] as
    | ChampStat[]
    | undefined;

  return useMemo<ChampStatsByRole>(() => {
    return roleSymbols.reduce((acc, role) => {
      // short circuit if role is already in rankings
      if (!championStats) return acc;
      if (acc[role]) return acc;
      const roleChamps = championStats
        .map((champ) => {
          champ.winPercent = champ.wins / champ.games;
          champ.avatarUrl = Static.getChampionImage(champ.championId);
          champ.key = Static.getChampionKeyFromId(champions, champ.championId);
          champ.name = Static.getChampionNameFromId(
            champions,
            champ.championId,
          );
          return champ;
        })
        .filter((c) => c.role === role);
      const sortedChampsByWinPercentage = [...roleChamps].sort((a, b) => {
        const aWinRate = a.winPercent;
        const bWinRate = b.winPercent;
        return bWinRate - aWinRate;
      });
      const sortedChampsByTier = [...roleChamps].sort((a, b) => {
        const aPickRate = a.pickRate;
        const bPickRate = b.pickRate;

        return bPickRate - aPickRate;
      });
      const bestTier = sortedChampsByTier[0];
      const worstTier = sortedChampsByTier[sortedChampsByTier.length - 1];
      const bestWins = sortedChampsByWinPercentage[0];
      const worstWins =
        sortedChampsByWinPercentage[sortedChampsByWinPercentage.length - 1];

      return {
        ...acc,
        [role]: {
          tier: {
            best: bestTier,
            worst: worstTier,
          },
          winRate: {
            best: bestWins,
            worst: worstWins,
          },
        },
      };
    }, {});
  }, [championStats, staticData, champions]);
}

export function useUniqueChampionsByRoles() {
  const stats = useSnapshot(readState);
  const { hash } = getChampionFilters(new URLSearchParams());
  const championStats = stats.lol.championStats[hash] as
    | ChampStat[]
    | undefined;

  return useMemo<ChampStat[]>(() => {
    if (!championStats) return new Array(24).fill({});
    const uniqueChampions = new Map<number, ChampStat>();
    championStats.forEach((champ) => {
      const existingChamp = uniqueChampions.get(champ.championId);
      if (existingChamp) {
        existingChamp.roles.push(champ.role);
        uniqueChampions.set(champ.championId, existingChamp);
      } else {
        champ.roles = [champ.role];
        uniqueChampions.set(champ.championId, champ);
      }
    });
    return Array.from(uniqueChampions.values()).sort((a, b) => {
      return b.pickRate - a.pickRate;
    });
  }, [championStats]);
}
