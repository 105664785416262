import { styled } from "goober";

export const HomeContainer = styled("section")`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: var(--sp-15);

  .page-header {
    height: var(--sp-14);
  }

  .logo {
    width: 100%;
    aspect-ratio: 320 / 80;
    max-width: 320px;
  }

  .loading {
    background: linear-gradient(
      to right,
      var(--shade7) 0%,
      var(--shade6) 25%,
      var(--shade7) 50%,
      var(--shade6) 75%,
      var(--shade7) 100%
    );
    background-size: 280px 140px;
    animation: shimmer 1s linear infinite forwards;

    @keyframes shimmer {
      from {
        background-position: -100% 0;
      }
      ,
      to {
        background-position: 100% 0;
      }
    }
  }
`;

export const CTAWrapper = styled("div")`
  margin: var(--sp-5) 0;
`;

export const ChampionStatsTitle = styled("h2")`
  margin: var(--sp-5) 0;
  text-align: center;

  strong {
    color: var(--primary);
  }
`;
