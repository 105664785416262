import { styled } from "goober";

export const ChampionStatsGrid = styled("div")`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 1fr;
  gap: var(--sp-4);

  @media (max-width: 950px) {
    grid-template-columns: repeat(4, 1fr);
  }
  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ChampionStatWrapper = styled("div")`
  background-color: var(--shade6);
  display: flex;
  flex-direction: column;
  height: 140px;
  width: 140px;
  position: relative;
  overflow: hidden;
  border-radius: var(--sp-2);
  transition: filter 0.1s ease;

  &:hover {
    filter: brightness(1.5);
  }

  .avatar {
    width: 140px;
    height: 140px;

    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: calc(100% + var(--sp-5));
      height: calc(100% + var(--sp-5));
      transition: transform 0.1s ease;
      will-change: transform;
      transform: scale(1);

      &:hover {
        transform: scale(1.02);
        transition: transform 0.1s ease;
      }
    }
  }

  .info {
    position: absolute;
    height: 100%;
    width: 100%;
    pointer-events: none;

    .roles {
      position: absolute;
      top: 0;
      left: 0;
      height: 36px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, 36px);
      grid-template-rows: 36px;

      .role {
        pointer-events: all;
        background-color: rgba(0, 0, 0, 0.8);
        height: 36px;
        width: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: scale(1);
        transform-origin: top left;
        transition: all 0.1s ease;
        will-change: transform;

        &:not(.primary) {
          svg {
            height: 24px;
            color: var(--shade2);
          }
        }

        &:last-child {
          border-bottom-right-radius: var(--sp-2);
        }

        &:hover {
          transform: scale(1.1);
          background-color: rgba(0, 0, 0, 1);
          transition: all 0.1s ease;
        }
      }
    }

    .name {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 0 var(--sp-2);
      font-size: var(--sp-3_5);
      color: var(--shade2);
      z-index: 1;
    }

    .pick-rate {
      position: absolute;
      bottom: 0;
      right: 0;
      padding-right: var(--sp-2);
      font-size: var(--sp-3);
      z-index: 1;
    }

    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 64px;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.9) 80%,
        rgba(0, 0, 0, 1) 100%
      );
    }
  }
`;
