import React from "react";
import { Trans, useTranslation } from "react-i18next";
import { Button } from "clutch/src/Button/Button.jsx";

import { appURLs } from "@/app/constants.mjs";
// import { TopAdContainer } from "@/feature-reskin-champion-gg/AdContainers.jsx";
import { ChampionStat } from "@/feature-reskin-champion-gg/ChampionStat.jsx";
import { ChampionStatsGrid } from "@/feature-reskin-champion-gg/ChampionStat.style.jsx";
import { ChampionTable } from "@/feature-reskin-champion-gg/ChampionTable.jsx";
import { ChampionListContainer } from "@/feature-reskin-champion-gg/ChampionTable.style.jsx";
import {
  ChampionStatsTitle,
  CTAWrapper,
  HomeContainer,
} from "@/feature-reskin-champion-gg/Home.style.jsx";
import {
  useChampionRankings,
  useUniqueChampionsByRoles,
} from "@/feature-reskin-champion-gg/hooks.mjs";
import { SearchContainer } from "@/feature-reskin-champion-gg/SearchContainer.jsx";
import MarketingFooter from "@/marketing/MarketingFooter.jsx";

export default function Home() {
  const uniqueChampionsWithMultipleRoles = useUniqueChampionsByRoles();
  const rankings = useChampionRankings();

  const { t } = useTranslation();

  return (
    <HomeContainer>
      <div className="page-header">
        <div className="sidebar-align" data-sticky-cumulative-height={50} />
      </div>
      <img
        className="logo"
        src={`${appURLs.CDN}/championgg/img/champion-gg-logo.png`}
        alt="Champion.gg logo"
      />
      <SearchContainer />
      {/* <TopAdContainer /> */}
      <CTAWrapper>
        <Button
          emphasis="high"
          size="large"
          href="https://blitz.gg/download"
          className="desktop-only"
        >
          {t("championgg:home.download", "Download Blitz Free")}
        </Button>
      </CTAWrapper>
      <ChampionListContainer>
        <ChampionTable
          title={["championgg:home.winRateTitle", "Win Rates"]}
          url="/statistics"
          dataKey="winRate"
          data={rankings}
        />
        <ChampionTable
          title={[
            "championgg:home.performanceTitle",
            "Overall Performance Rating",
          ]}
          url="/statistics/combat"
          dataKey="tier"
          data={rankings}
        />
      </ChampionListContainer>
      <ChampionStatsTitle>
        <Trans i18nKey="championgg:home.championStatsTitle">
          Choose a Champion for In-Depth <strong>Statistics</strong> and{" "}
          <strong>Counters</strong>
        </Trans>
      </ChampionStatsTitle>
      <ChampionStatsGrid>
        {uniqueChampionsWithMultipleRoles.map((champ, i) => (
          <ChampionStat key={champ.championId || i} champion={champ} />
        ))}
      </ChampionStatsGrid>
      <MarketingFooter />
    </HomeContainer>
  );
}

export function meta() {
  return {
    title: [
      "championgg:home.meta.title",
      "Champion.gg - LoL Champion Stats, Guides, Builds, Runes, Masteries, Counters and Matchups!",
    ],
    description: [
      "championgg:home.meta.description",
      "Champion.gg provides League of Legends champion statistics, guides, builds, runes, masteries, skill orders and counters by role - including Win Rate, Ban Rate, Play Rate and much more!",
    ],
  };
}
// https://blitz-cdn.blitz.gg/championgg/img/champion-gg-logo.png
